<template>
  <div class="relative w-full overflow-hidden">
    <div
      class="flex w-max will-change-transform"
      :class="
        (index + 1) % 2 === 0
          ? 'animate-[translate-x_200s_infinite_linear_reverse]'
          : 'animate-[translate-x_200s_infinite_linear]', { '!animate-pause': hover }
      "
    >
      <template v-for="i in 2" :key="i">
        <div class="flex min-w-[100vw] items-center justify-around">
          <template v-for="logo in partners" :key="logo.slug">
            <Component
              :is="divOrNuxtLink(logo.link)"
              v-bind="logo.link ? { to: logo.link } : {}"
              :target="$isExternalUrl(logo.link) ? '_blank' : ''"
              :aria-label="logo.link ? `Link to ${logo.title}` : ''"
              tabindex="-1"
              class="mx-10 flex h-[3.75rem] w-[7.5rem] items-center justify-center transition-transform duration-300 sm:mx-16 sm:h-[4rem] sm:w-[12rem]"
              :class="{ 'hover:scale-110': logo.link }"
              @mouseover="logo.link ? (hover = true) : null"
              @mouseleave="hover = false"
            >
              <AppImage
                v-if="logo.image"
                :src="logo.image"
                class="max-h-[3.75rem] max-w-[7.5rem] select-none object-contain opacity-60 grayscale sm:max-h-[4rem] sm:max-w-[12rem]"
                loading="lazy"
              />
            </Component>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
})

const allPartners = ref([])
const slider = ref(null)
const hover = ref(false)

// COMPUTED
const partners = computed(() => {
  if (props.data.partner_logos_selection.value === 'custom') {
    return props.data.partner_logos
  } else {
    return allPartners.value
  }
})

// determine whether to load NuxtLink or div
function divOrNuxtLink(url) {
  if (url) {
    return resolveComponent('NuxtLink')
  }

  return 'div'
}

// INITIAL LOAD
if (props.data.partner_logos_selection.value !== 'custom') {
  // Get all partner logos

  const { data } = await useAsyncGql({
    operation: 'Partners',
  })

  allPartners.value = data?.value?.entries?.data || []

  if (!allPartners.value) {
    console.error('Could not fetch partner in PartnerLogoRow.vue ')
  }
}
</script>
